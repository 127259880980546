import { useContext, useEffect, useState } from 'react'
import { TableContext } from '../../contexts/TableContext'
import { Pagination } from '@mui/material'
import { api } from '../../services/api'
import { useParams } from 'react-router-dom'

import search from '../../assets/icons/search.svg'
import filter from '../../assets/icons/filter.svg'
import noDocument from '../../assets/icons/no__document.svg'
import TableRow from '../../components/TableRow'
import SubHeader from '../../components/SubHeader'
import Navbar from '../../components/Navbar'

import './styles.css'

export default function Backups() {
  const COMPONENTS_PER_PAGE = 20

  const { clientId } = useParams()

  const { handleFilterChange, filterProps, setBackups, backups } = useContext(TableContext)

  const [pages, setPages] = useState<number>(0)
  const [pageData, setPageData] = useState<any[] | null>([])
  const [waitingAPIRequest, setWaitingAPIRequest] = useState(false)

  const MONTH = new Date().getMonth() + 1
  const YEAR = new Date().getFullYear().toString()
  const LAST_DAY_OF_MONTH = new Date(Number(YEAR), Number(MONTH), 0).getDate()

  useEffect(() => {
    loadLastBackups()
    setupFilterDates()
    handlePaginationChange(null, 1)
  }, [])

  async function loadLastBackups() {
    try {
      const { data } = await api.get(`/backups/ultimos/cliente/${clientId}`)

      const dataSplited = String(data && data.length / COMPONENTS_PER_PAGE).split('.')

      if (dataSplited[1]) {
        setPages(Number(dataSplited[0]) + 1)
      } else {
        setPages(Number(dataSplited[0]))
      }

      setPageData(data?.slice(0, COMPONENTS_PER_PAGE) || [])
      setBackups(data)
    } catch (err) {
      console.log(err)
    }
  }

  function handlePaginationChange(event: any, value: number) {
    const startIndex = value * COMPONENTS_PER_PAGE - COMPONENTS_PER_PAGE
    const lastIndex = startIndex + COMPONENTS_PER_PAGE

    if (backups?.length) {
      setPageData(backups && backups.slice(startIndex, lastIndex))
    }
  }

  function setupFilterDates() {
    const actualMonth = (new Date().getMonth() + 1).toString().padStart(2, '0')
    const actualYear = new Date().getFullYear().toString()

    filterProps.initialDate = `${actualYear}-${actualMonth}-01`
    filterProps.finalDate = `${actualYear}-${actualMonth}-${LAST_DAY_OF_MONTH}`
  }

  async function handleFilter(e: any) {
    e.preventDefault()

    const { initialDate, finalDate, cnpj } = filterProps

    try {
      let url = `/backups/filtrar/${clientId}/${initialDate}/${finalDate}`

      if (cnpj) {
        url += `?cnpj=${cnpj}`
      }

      const { data } = await api.get(url)

      const dataSplited = String(data && data.length / COMPONENTS_PER_PAGE).split('.')

      if (dataSplited[1]) {
        setPages(Number(dataSplited[0]) + 1)
      } else {
        setPages(Number(dataSplited[0]))
      }

      setPageData(data?.slice(0, COMPONENTS_PER_PAGE) || [])
      setBackups(data || [])
    } catch (err) {}
  }

  return (
    <div className="docs">
      <Navbar />

      <div className="container">
        <SubHeader />

        <div className="tables">
          <div className="main box">
            <div className="content">
              <div className="actions">
                <form
                  onSubmit={(e) => {
                    handleFilter(e)
                  }}
                >
                  <div className="row">
                    <div className="input__block">
                      <label htmlFor="initialDate">data inicial *</label>
                      <div className="input date">
                        <input
                          required={
                            !!(Object.keys(filterProps).find(
                              (key, keyIndex) =>
                                !['key', 'documentNumber'].includes(key) && Object.values(filterProps)[keyIndex]
                            )
                              ? true
                              : false)
                          }
                          value={filterProps.initialDate}
                          type="date"
                          id="initialDate"
                          name="initialDate"
                          onChange={(e) => handleFilterChange({ key: 'initialDate', value: e.target.value })}
                        />
                      </div>
                    </div>

                    <div className="input__block">
                      <label htmlFor="finalDate">data final *</label>
                      <div className="input date">
                        <input
                          required={
                            !!(Object.keys(filterProps).find(
                              (key, keyIndex) =>
                                !['key', 'documentNumber'].includes(key) && Object.values(filterProps)[keyIndex]
                            )
                              ? true
                              : false)
                          }
                          value={filterProps.finalDate}
                          type="date"
                          id="finalDate"
                          name="finalDate"
                          onChange={(e) => handleFilterChange({ key: 'finalDate', value: e.target.value })}
                        />
                      </div>
                    </div>

                    <button type="submit" id="searchButton">
                      <img src={search} alt="search" />
                    </button>
                  </div>
                </form>
              </div>

              {waitingAPIRequest ? (
                <div className="loading"></div>
              ) : pageData?.length ? (
                <>
                  <table>
                    <thead>
                      <tr>
                        <th align="left">ID</th>
                        <th align="left">Cliente</th>
                        <th align="left">Data</th>
                        <th align="right">Status</th>
                      </tr>
                    </thead>

                    <tbody>
                      {pageData.map((item) => (
                        <TableRow key={item.id} item={item} />
                      ))}
                    </tbody>
                  </table>

                  <div className="table__footer">
                    {backups && backups.length > 5 ? (
                      <Pagination
                        count={pages}
                        color="primary"
                        variant="outlined"
                        shape="rounded"
                        onChange={handlePaginationChange}
                      />
                    ) : null}
                  </div>
                </>
              ) : backups === null ? (
                <div className="table__warning">
                  <img src={filter} alt="filter" />
                  <h1>
                    utilize os filtros acima <br /> para listar os documentos
                  </h1>
                </div>
              ) : (
                <div className="table__warning">
                  <img src={noDocument} alt="no-document" />
                  <h1>
                    não encontramos backups <br /> para listar utilizando <br /> os filtros acima
                  </h1>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
