import InfoSharp from '@mui/icons-material/InfoSharp'

import './styles.css'

interface TableRowProps {
  item: ItemProps
}

interface ItemProps {
  id: number
  id_cliente: number
  cliente: string
  status: number
  data: string
  total_arquivos_backup: number
  erros_backup: number
}

export default function TableRow({ item }: TableRowProps) {
  const { data, id, cliente, status, erros_backup, total_arquivos_backup } = item

  const convertedToDateType = new Date(data)

  const date = `${convertedToDateType.getDate().toString().padStart(2, '0')}/${(convertedToDateType.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${convertedToDateType.getFullYear()} ${convertedToDateType
    .getHours()
    .toString()
    .padStart(2, '0')}:${convertedToDateType.getMinutes().toString().padStart(2, '0')}:${convertedToDateType
    .getSeconds()
    .toString()
    .padStart(2, '0')}`

  return (
    <tr className="table__row__backup" onClick={() => (window.location.href = `/backups/${id}/files`)}>
      <td>{id}</td>
      <td>{cliente}</td>
      <td>{date}</td>
      <td align="right">
        <div className="agroup">
          {erros_backup || total_arquivos_backup === 0 ? <InfoSharp fontSize="medium" color="warning" /> : null}
          {status === 1 ? 'Finalizado' : 'Em progresso'}
        </div>
      </td>
    </tr>
  )
}
