import { useState, createContext, Dispatch, SetStateAction, useEffect } from 'react'

interface TableContextType {
  documentType: string
  backups: BackupProps[] | null
  filterProps: FilterProps
  setBackups: Dispatch<SetStateAction<BackupProps[]>>
  handleFilterChange: (filterField: { key: string; value: string | number | undefined }) => void
}

export interface BackupProps {
  id: number
  cnpj: string
  status: number
  data: Date
}

export interface FilterProps {
  initialDate: string
  finalDate: string
  cnpj?: string
}

export const TableContext = createContext({} as TableContextType)

export function TableProvider({ children }: any) {
  const [documentType, setDocumentType] = useState('nfe')
  const [filterProps, setFilterProps] = useState<FilterProps>({} as FilterProps)
  const [backups, setBackups] = useState<BackupProps[]>([])

  useEffect(() => {
    const date = new Date()

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const todayInDateString = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`
    const firstDayOfMonth = `${year}-${String(month).padStart(2, '0')}-01`

    setFilterProps({
      initialDate: firstDayOfMonth,
      finalDate: todayInDateString,
    })
  }, [])

  function handleFilterChange(filterField: { key: string; value: string | number | undefined }) {
    setFilterProps((prevState) => ({ ...prevState, [filterField.key]: filterField.value }))
  }

  return (
    <TableContext.Provider
      value={{
        setBackups,
        backups,
        documentType,
        filterProps,
        handleFilterChange,
      }}
    >
      {children}
    </TableContext.Provider>
  )
}
