import { useState } from 'react'
import { api } from '../../services/api'
import { AxiosError } from 'axios'

import peaceImage from '../../assets/images/peacedb.png'
import emailIcon from '../../assets/icons/email.svg'
import lockIcon from '../../assets/icons/lock.svg'
import Toast from '../../components/Toast'

import './styles.css'

export default function Login() {
  const [toastType, setToastType] = useState<'error' | 'alert'>('error')
  const [toastVisible, setToastVisible] = useState<boolean>(false)
  const [toastMessage, setToastMessage] = useState<string>('')

  const [user, setUser] = useState('')
  const [password, setPassword] = useState('')

  const [waitingRequest, setWaitingRequest] = useState(false)

  async function handleSubmit(e: any) {
    e.preventDefault()

    setWaitingRequest(true)

    try {
      const { data } = await api.post('/usuarios/login', { email: user, senha: password })

      setWaitingRequest(false)

      if (data.token) {
        sessionStorage.setItem('peacedb@token', data.token)
        const redirect = sessionStorage.getItem('peacedb@redirect')

        if (redirect) {
          sessionStorage.removeItem('peacedb@redirect')
          window.location.href = redirect
        } else {
          window.location.href = '/dashboard'
        }
      }
    } catch (err) {
      setWaitingRequest(false)

      const axiosError = err as AxiosError

      setToastType('error')
      setToastVisible(true)
      setToastMessage(axiosError.response?.status === 401 ? 'CREDENCIAIS INCORRETAS' : 'FALHA NA REQUISIÇÃO')

      setTimeout(() => {
        setToastVisible(false)
      }, 4000)
    }
  }

  return (
    <div className="App">
      <div className="form__box">
        <form onSubmit={(e) => handleSubmit(e)}>
          <img src={peaceImage} alt="peace-db" className="logo__app" />

          <div className="input__box">
            <input type="email" name="cpf" required placeholder="E-MAIL" onChange={(e) => setUser(e.target.value)} />
            <img src={emailIcon} alt="e-mail" />
          </div>

          <div className="input__box">
            <input type="password" required placeholder="SENHA" onChange={(e) => setPassword(e.target.value)} />
            <img src={lockIcon} alt="password" />
          </div>

          <Toast visible={toastVisible} type={toastType} title={toastMessage} />

          <button disabled={waitingRequest} className={waitingRequest ? 'button__loading' : ''} type="submit">
            <span>ENTRAR</span>
          </button>
        </form>
      </div>
    </div>
  )
}
