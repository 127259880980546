import { Client } from '../../pages/home'

import { ListItemIcon } from '@mui/material'
import InfoSharp from '@mui/icons-material/InfoSharp'
import ReportProblem from '@mui/icons-material/ReportProblem'
import NotificationsActive from '@mui/icons-material/NotificationsActive'
import CheckBoxRounded from '@mui/icons-material/CheckBoxRounded'

import './styles.css'

interface TableRowProps {
  client: Client
}

export default function TableRowClient({ client }: TableRowProps) {
  const now = new Date(new Date().setUTCHours(new Date().getHours())).getTime()
  const date = new Date(client.ultimo_backup)

  const backupInterval = Math.abs(new Date(date).getTime() - now) / (60 * 60 * 1000)

  const formatedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`

  return (
    <tr
      className="table__row__client"
      onClick={() => {
        window.location.href = `/backups/client/${client.id}`
      }}
    >
      <td>
        <ListItemIcon>
          {backupInterval > 4 && backupInterval < 8 ? (
            <ReportProblem fontSize="medium" color="warning" />
          ) : backupInterval > 8 ? (
            <NotificationsActive fontSize="medium" color="error" />
          ) : (
            <CheckBoxRounded fontSize="medium" color="success" />
          )}
        </ListItemIcon>
      </td>
      <td>{client.nome}</td>
      <td align="right">
        <div className="agroup">
          {client.erros_ultimo_backup || client.total_arquivos_ultimo_backup === 0 ? (
            <InfoSharp fontSize="medium" color="warning" />
          ) : null}
          {formatedDate}
        </div>
      </td>
    </tr>
  )
}
